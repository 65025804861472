var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list_container" },
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c(
            "i-header",
            {
              attrs: { title: _vm.name ? _vm.name : "", showBack: true },
              on: { "back-event": _vm.toBack },
            },
            [
              _vm.config.rightTitle
                ? _c(
                    "div",
                    {
                      staticStyle: { color: "var(--themeColor)" },
                      attrs: { slot: "right" },
                      on: {
                        click: function ($event) {
                          return _vm.handleClick(_vm.config)
                        },
                      },
                      slot: "right",
                    },
                    [_vm._v(_vm._s(_vm.config.rightTitle) + " ")]
                  )
                : _vm._e(),
            ]
          ),
          _c("i-search", {
            attrs: {
              shape: "round",
              clearable: "",
              "clear-trigger": "always",
              placeholder:
                "请输入会议名称/" +
                (_vm.tenantCode === "dsm" ? "申请书" : "会议") +
                "编号" +
                (_vm.tenantCode === "crossevent" ? "/预算编号" : ""),
            },
            on: { input: _vm.onSearch },
            model: {
              value: _vm.searchKey,
              callback: function ($$v) {
                _vm.searchKey = $$v
              },
              expression: "searchKey",
            },
          }),
        ],
        1
      ),
      _c("event-item-list", {
        ref: "eventlist",
        on: { updateList: _vm.onSearch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }