<template>
    <div class="list_container">
        <div class="search-box">
            <i-header :title="name ? name : ''"
                      :showBack="true" @back-event="toBack">
                <div style="color: var(--themeColor)" slot="right" v-if="config.rightTitle"
                     @click="handleClick(config)">{{ config.rightTitle }}
                </div>
            </i-header>
            <i-search v-model="searchKey"
                      shape="round"
                      clearable
                      clear-trigger="always"
                      @input="onSearch"
                      :placeholder="`请输入会议名称/${tenantCode === 'dsm' ? '申请书' : '会议'}编号${tenantCode === 'crossevent' ? '/预算编号': ''}`"></i-search>
        </div>
        <event-item-list ref="eventlist" @updateList="onSearch"></event-item-list>
    </div>
</template>

<script>
/**
 * @description 会议列表公共组件，没有filter，联系客服，只有搜索和展示，以及跳转详情,所有参数都来自url的传递
 * 这个组件，目前只有在会务支持和申请授权中使用到
 */
import EventItemList from "../../views/EventList/EventItemList";
import {debounce} from 'lodash'

export default {
    name: "index",
    components: {
        EventItemList
    },
    data() {
        return {
            name: '', // 标题
            config: {}, // 页面相关配置，通过url参数传递
            tenantCode: this.$smartStorage.get("tenant") || this.$cookie.get("tenant"),
            searchKey: this.$smartStorage.get('lastListParams')?.searchKey || "",
            isAuthApply: '', // 是否授权申请页面（区分授权申请还是会务支持）
            // activeName: '1'
        }
    },
    methods: {
        handleClick(config) {
            const query = {
                title: '申请记录',
                showTabs: true
            }
            console.log(this.$smartStorage.get("homeQueryListExpr").listExpr);
            this.$router.push({
                path: config.path + '?config=' + JSON.stringify(query) + '&queryListExpr=' + this.$smartStorage.get("homeQueryListExpr").listExpr + '&showApplyStatus=false&blockToDetails=true'
            });
        },
        toBack() {
            this.$router.replace('/application');
        },
        onSearch: debounce(function () {
            if(this.isAuthApply){
                if(this.searchKey){
                    this.triggerSearch()
                }else {
                    this.$refs.eventlist.eventList = []
                }
            }else {
                this.triggerSearch();
            }
        }, 800),
        triggerSearch(){
            this.$root.$eventHub.$emit("updateEventList", {
                searchKey: this.searchKey,
                filterData: {
                    Status: this.activeName,
                    Flag: 0
                }
            })
        },
    },
    created() {
        const {config, queryListExpr, name} = this.$route.query;
        console.log('name',name);
        this.name = name;
        this.config = JSON.parse(config);
        this.$smartStorage.set("homeQueryListExpr", {listExpr: queryListExpr});
    },
    mounted() {
        console.log('config',this.config);
        if(this.name === '授权申请'){ // 住友授权申请的时候不能及时搜索
            this.isAuthApply = true;
            this.$refs.eventlist.isImmediateSearch = true;
        }else {
            this.isAuthApply = false;
        }
        // 重置列表高度
        console.log(this.$refs.eventlist);
        this.$refs.eventlist.iheight = "calc(100vh - 0.9rem)";
    }
}
</script>

<style scoped lang="less">
.search-box {
    /deep/ .van-search {
        margin: 0.1rem 0.1rem;
        //width: 100%;
        padding: unset;
        background: #F5F5F5;
        border-radius: 0.04rem;

        .van-search__content {
            height: 0.3rem;
            background: #F5F5F5;
            //box-shadow: 0px 0.02rem 0.13rem 0px rgba(35, 24, 21, 0.1);
            display: flex;
            align-items: center;
            font-size: 0.14rem;

            .van-cell {
                height: 0.3rem;
                box-sizing: border-box;
                display: flex;
                align-items: center;
            }

            .van-field__control {
                height: 0.3rem;
            }

            .van-field__control::placeholder {
                color: #909399;
                -webkit-text-fill-color: #909399 !important;
            }
        }
    }

    /deep/ .van-icon-search {
        color: #c0c4cc;
    }
}

</style>
